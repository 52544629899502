import { createContext, useEffect, useState, useContext } from "react";
import { LanguageContext } from '../languages/languages';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);
    const [isUserLoaded, setIsUserLoaded] = useState(false); // Состояние для отслеживания загрузки пользователя

    // Function to set user data and update localStorage
    const SetUserData = (key, value) => {
        try {
            const updatedUser = { ...user, [key]: value };
            setUser(updatedUser);
            localStorage.setItem("user", JSON.stringify(updatedUser));
        } catch (e) {
            console.error('Failed to save user data:', e);
        }
    };

    const Logout = () => {
        localStorage.removeItem("user");
        setUser({});
    };

    // Этот эффект выполняется только после того, как пользователь загружен из localStorage
    useEffect(() => {
        if (isUserLoaded) { // Убедимся, что пользователь загружен
            const selectedLanguage = user?.info?.language;
            console.log("User language effect:", selectedLanguage);

            // Меняем язык только если он отличается от текущего
            if (selectedLanguage && userLanguage !== selectedLanguage) {
                console.log("Changing language to:", selectedLanguage);
                userLanguageChange(selectedLanguage);
            }
        }
    }, [user, isUserLoaded, userLanguage, userLanguageChange]);

    // Инициализация данных пользователя из localStorage
    useEffect(() => {
        const initUser = () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem("user")) || {};
                setUser(storedUser);
                setIsUserLoaded(true); // Устанавливаем флаг, что данные загружены
            } catch (e) {
                console.error("Error loading user data:", e);
            }
        };
        initUser();
    }, []);

    const value = { user, SetUserData, Logout };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider };
export const useUser = () => useContext(UserContext);
