import { Fragment, useState } from "react";
import { Toolbar, Typography, IconButton, Box, Tooltip,useMediaQuery, Menu, MenuItem } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { useUser } from "../context/user"
import { useTheme } from '@mui/material';
import { LogoW, LogoRainbow } from "../functions/SvgIcons";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { LanguageSelector } from "../functions/LanguageSelector"
import { Text } from "../languages/languages";
import { MailOutline } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectPos from "./selectPOS";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FadedTextCycle from "../functions/FadedTextCircle";
import MenuIcon from '@mui/icons-material/Menu'; // Icon for mobile menu
import MoreVertIcon from '@mui/icons-material/MoreVert';



const LoginToolbar = () => {
  // let location = useLocation()
  let theme = useTheme();
  // let language = useLanguage()
  let isDark = (theme?.palette.mode === "dark");

  let user = useUser()
  console.log("APPBAR", user);
  let link = "/";
  if (user && user.user && user.user.token) {
    console.log("title", user.user.token);

    link = "/desktop";
  } else {
    link = "/";
  }

  return (
    <Toolbar sx={{ display: 'flex', flexGrow: 1 }}>
      {isDark ? <LogoW /> : <LogoRainbow />}
      <Typography variant='h2' mt={0} component={Link} to={link} sx={{ fontWeight: 900 }} style={{ textDecoration: 'inherit', color: 'inherit' }}>micro</Typography>
      {/* <Typography variant='h2' mt={0} component={Link} to={link} sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>business</Typography> */}
      <FadedTextCycle textArray={['business', 'restaurant', 'hotel' ]} interval={2000} component={Link} to={link}/>
      {/* <Typography variant='caption' component={Link} to={link}  sx={{ display: 'flex', flexGrow: 1, fontStyle: 'italic', fontWeight: 'lighter' }} mt={"1.84rem"} ml={-6.5} style={{ textDecoration: 'inherit', color: 'inherit' }}>software </Typography> */}
      <Box sx={{ flexGrow: 1 }} />


      <LanguageSelector />
      {/* <Divider sx={{ width: "16px" }} /> */}


      {link === "/" ?
        <div>
          <Typography variant='body' mt={0} component={Link} to="/registration" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}><Text tid={"_Registration"} /></Typography>
          <Typography variant='body' mt={0} sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>  /  </Typography>
          <Typography variant='body' mt={0} component={Link} to="/sign" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}><Text tid={"_Sign"} /></Typography>
        </div>
        :
        <IconButton component={Link} to="/desktop">
          <DashboardOutlinedIcon />
        </IconButton>
      }
    </Toolbar>
  )
}


const DesktopToolbar = () => {
  let location = useLocation();
  let theme = useTheme();
  let user = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens
  let isDark = (theme?.palette.mode === "dark");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar sx={{ display: 'flex', flexGrow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minWidth: 'fit-content' }}>
        <Box sx={{ width: 'auto' }}>
          {isDark ? <LogoW /> : <LogoRainbow />}
        </Box>
        <Box sx={{ ml: 1, minWidth: '120px' }}>
          <SelectPos onSelect={(select) => console.log(select)} />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }} /> {/* This will push the right content to the right */}

      <Box>
        {location.pathname === "/" ? (
          <IconButton component={Link} to="/desktop">
            <DashboardOutlinedIcon />
          </IconButton>
        ) : !isMobile ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={<Fragment><Typography color="inherit"><Text tid="_sync" /></Typography></Fragment>}>
              <IconButton component={Link} to="/desktop">
                <RefreshIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={<Fragment><Typography color="inherit"><Text tid="_messages" /></Typography></Fragment>}>
              <IconButton component={Link} to="/desktop/messages">
                <MailOutline />
              </IconButton>
            </Tooltip>

            <Tooltip title={<Fragment><Typography color="inherit"><Text tid="_sign_out" /></Typography></Fragment>}>
              <IconButton aria-label="sign out" onClick={() => user.Logout()}>
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box>
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/desktop" onClick={handleMenuClose}>
                <DashboardOutlinedIcon />
                <Typography sx={{ ml: 1 }}>Dashboard</Typography>
              </MenuItem>
              <MenuItem component={Link} to="/desktop" onClick={handleMenuClose}>
                <RefreshIcon />
                <Typography sx={{ ml: 1 }}>Sync</Typography>
              </MenuItem>
              <MenuItem component={Link} to="/desktop/messages" onClick={handleMenuClose}>
                <MailOutline />
                <Typography sx={{ ml: 1 }}>Messages</Typography>
              </MenuItem>
              <MenuItem onClick={() => { user.Logout(); handleMenuClose(); }}>
                <ExitToAppIcon />
                <Typography sx={{ ml: 1 }}>Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
    </Toolbar>
  );
};


export { LoginToolbar, DesktopToolbar }