import React, { useState,useEffect } from 'react';
import { Select, MenuItem, FormControl, Box, Typography, Container, InputBase, Button } from '@mui/material';
import { Text, useLanguage } from '../languages/languages';
import { useUser } from '../context/user';
import { useNavigate } from 'react-router-dom';

const SelectPos = ({onSelect}) => {
    const user = useUser();
    const language = useLanguage()
    // const nav = useNavigate()

    const [openIndex, setOpenIndex] = useState(null); // Track the currently open index
    const [selectedPos, setSelectedPos] = useState(""); // Track the selected position
    const [posList, setPosList] = useState(user?.user?.pos || []);
   
    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleSelectChange = (event) => {
        setSelectedPos(event.target.value); // Set the selected position
        onSelect(posList[event.target.value])
    };

    useEffect(() => {
        setPosList(user?.user?.pos || []);
    },[user])

    useEffect(() => {
        if (posList.length > 0) {
            const defaultPos = user?.user?.info?.currentPos || 0;
            setSelectedPos(defaultPos);
            onSelect(posList[defaultPos]);
        }
        // eslint-disable-next-line
    }, [posList]);

    const nav = useNavigate();
    return (
        <div>
            {posList.length>0?
            <FormControl sx={{width:"14rem"}}>
                {/* <InputLabel id="pos-select-label">{language.dictionary.select_position || "Select Position"}</InputLabel> */}
                <Select
                    variant="standard"
                    input={<InputBase sx={{ borderBottom: 'none', textAlign: 'left' }} />}  // Remove underline
                    labelId="pos-select-label"
                    value={selectedPos}
                    onChange={handleSelectChange}
                    // label={language.dictionary.select_position || "Select Position"}
                    renderValue={(selected) => {
                        const pos = posList[selected];
                        return (
                            <Box alignContent={'left'}>
                                <Typography variant="h6">{`${language.dictionary[pos.pos_type]} "${pos.PosTitle}"`}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {`${pos.city}, ${pos.street} ${pos.houseNumber}`}
                                </Typography>
                            </Box>
                        );
                    }}
                >
                    {Array.isArray(posList) && posList.length > 0 && (
                        posList.map((pos, index) => (
                            <MenuItem
                                key={index}
                                sx={{ textAlign: 'left' }}
                                value={index}
                                onClick={() => handleToggle(index)}
                            >
                                <Container>
                                 <Typography variant="body1">
                                    {`${language.dictionary[pos.pos_type]} "${pos.PosTitle}"`}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {`${pos.city}, ${pos.street} ${pos.houseNumber}`}
                                </Typography>
                                </Container>
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
            :
            <div>
                <Button variant="outlined"  onClick={() => { nav("desktop/new_pos") }} color="primary" sx={{ width: '10rem', m: 0, px: 0, py: "0.5rem" }}>
                    <Text tid="_add_pos"></Text>
                </Button>  
            <Box/>
            </div>
            }
        </div>
    );
};

export default SelectPos;
