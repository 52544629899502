import {Container, Typography,CssBaseline, useMediaQuery} from "@mui/material"
import  { Fragment } from "react";
import { Text, useLanguage } from "../languages/languages";
import { useTheme } from '@mui/material/styles';
import { LogoBig, LogoBigRainbow } from "../functions/SvgIcons";
import SEO from '../functions/seo'; // Убедитесь, что путь к компоненту правильный




const Landing = (props) => {
const isDark = useMediaQuery('(prefers-color-scheme: dark)');

const theme = useTheme();
const lang = useLanguage();
return (
    <Fragment>
        <SEO
        title={lang.dictionary["seo_landing_title"]} 
        description={lang.dictionary["seo_landing_description"]}
      />
    <CssBaseline enableColorScheme />
    <Container mx = {0} background={theme.palette.background} 
    color = {theme.palette.primary} 
    sx={{ height:'100vh', justifyContent:'center', flexDirection:'row' }}  >
    <Typography variant="h1" sx={{justifyContent:'center', mt:"30vh"}} color={"primary"}>
    {isDark?<LogoBig Color={'#f0f0f010'}/>:<LogoBigRainbow /> }
        {/* <LogoBigRainbow Color={'#00ffff'}/> */}
        </Typography>
       <Typography variant="h6" sx={{justifyContent:'center'}} color={"primary"}>     
       <Text tid="Still in development, but you can test it..." /> 
       {/* Все еще находится в разработке, но вы можете протестировать его... */}
        </Typography>
    </Container>
        </Fragment>
)
}

export default  Landing;