import { createTheme, responsiveFontSizes } from '@mui/material';

// Общие стили типографики
const commonTypography = {
  fontFamily: 'Roboto', // Единый шрифт для обеих тем
  h1: {
    fontSize: '2rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.5rem', // Обратите внимание на исправление в значении '1.25rem'
    fontWeight: 700,     // Убедитесь, что используется корректное числовое значение
    letterSpacing: '0.001em',
  },
  h3: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  h6: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 300,
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
};

// Светлая тема
let light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0b222d',
      contrastText: '#f5f5f5',
    },
    secondary: {
      main: '#0b222d',
    },
    background: {
      default: '#f5f5fa',
      paper: '#f5f5fa',
    },
    info: {
      main: '#ffd600',
    },
    divider: '#ebeced',
    text: {
      main: '#000',
      primary: '#000',
    }
  },
  typography: commonTypography, // Применяем общие стили
});

// Тёмная тема
let dark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#f5f5f5',
      contrastText: '#0b222d',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      main: "#0b222d",
      default: '#0b222d',
    },
    info: {
      main: '#ffd600',
    },
    divider: '#233842',
    text: {
      primary: '#FFFFFF',
    }
  },
  typography: commonTypography, // Применяем общие стили
});

// Применение адаптивности шрифтов
light = responsiveFontSizes(light);
dark = responsiveFontSizes(dark);

export { light, dark };
