import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const FadedTextCycle = ({ textArray, interval, component, to }) => {
  const [index, setIndex] = useState(0);  // Текущий индекс строки
  const [isFading, setIsFading] = useState(true);  // Контролируем состояние анимации

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setIsFading(false); // Начинаем fade-out
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % textArray.length);  // Смена строки
        setIsFading(true);  // Включаем fade-in после смены
      }, 700);  // Время на завершение fade-out
    }, interval);

    return () => clearTimeout(fadeTimeout);
  }, [index, interval, textArray.length]);

  // Инлайн стили для fade
  const fadeStyles = {
    transition: 'opacity 0.5s ease-in-out',
    textDecoration: 'inherit', 
    color: 'inherit',
    fontWeight: 100,
    // FontFace: 'Roboto Light',
    opacity: isFading ? 1 : 0,
  };

  return (
    <Typography variant = "h2" style={fadeStyles} component={component} to={to} >
      {textArray[index]}
    </Typography>
  );
};

export default FadedTextCycle;



// import React, { useState, useEffect } from 'react';
// import { Typography, Fade } from '@mui/material';

// const FadedTextCycle = ({ textArray, interval }) => {
//   const [index, setIndex] = useState(0);  // Текущий индекс строки
//   const [fadeIn, setFadeIn] = useState(true); // Контролируем видимость

//   // Переход к следующему элементу массива с fade-эффектом
//   useEffect(() => {
//     const fadeTimeout = setTimeout(() => {
//       setFadeIn(false);  // Начать fade-out

//       setTimeout(() => {
//         setIndex((prevIndex) => (prevIndex + 1) % textArray.length); // Цикл по массиву
//         setFadeIn(true);  // Вернуть fade-in после смены строки
//       }, 500);  // Время для fade-out (500ms)

//     }, interval);

//     return () => clearTimeout(fadeTimeout); // Очищаем таймер
//   }, [index, interval, textArray.length]);

//   return (
//     <Fade in={fadeIn} timeout={500}> {/* Указываем таймаут для анимации */}
//       <Typography
//         variant='h2'
//         sx={{ fontWeight: 'lighter', textDecoration: 'inherit', color: 'inherit' }}
//       >
//         {textArray[index]}
//       </Typography>
//     </Fade>
//   );
// };

// export default FadedTextCycle;
