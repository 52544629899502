import { useState, createContext, useContext, useEffect } from 'react';
import { languageOptions, dictionaryList } from './translations/index';
import { useUser } from '../context/user';

// Create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
  userLanguageChange: () => { }
});

export const useLanguage = () => useContext(LanguageContext);

// It provides the language context to app
export const LanguageProvider = ({ children }) => {
  const { user, SetUserData } = useUser(); // Destructure 'user' and 'SetUserData'

  // Determine the default language based on user or browser language
  const getDefaultLanguage = () => {
    const userLanguage = user?.info?.language;
    const browserLanguage = navigator.language?.substring(0, 2);
    const initialLanguage = userLanguage || browserLanguage;
    return dictionaryList[initialLanguage] ? initialLanguage : 'en';
  };

  const [userLanguage, setUserLanguage] = useState(getDefaultLanguage);

  useEffect(() => {
    // Update document language attribute when userLanguage changes
    document.documentElement.lang = userLanguage;
  }, [userLanguage]);

  // Effect to update language when user changes (after user data is loaded)
  useEffect(() => {
    if (user?.info?.language && user?.info?.language !== userLanguage) {
      setUserLanguage(user.info.language);
    }
  }, [user, userLanguage]);

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'en';
      setUserLanguage(newLanguage);
      SetUserData("language", newLanguage); // Update user data with new language
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// Component to get text by ID and current language
export const Text = ({ tid }) => {
  const { dictionary } = useContext(LanguageContext);
  return dictionary[tid] || tid;
};
